import { Component } from '@angular/core';
import { TaxMandatoryType } from '@app/models';
import { theme, THEMES } from 'src/themes/theme';
import { OnboardingService } from '../onboarding-service';
import { Logger } from '@uni-framework/core/logger';

@Component({
    selector: 'init-tax-type',
    templateUrl: './tax-type.html',
    styleUrls: ['./tax-type.sass'],
})
export class InitTaxType {
    theme = theme.theme;
    themes = THEMES;

    taxType = TaxMandatoryType;
    selectedType = TaxMandatoryType.TaxMandatory;

    constructor(
        public onboardingService: OnboardingService,
        private logger: Logger,
    ) {}

    ngOnInit() {
        if (this.onboardingService.state()?.CompanySettings?.TaxMandatoryType) {
            this.selectedType = this.onboardingService.state().CompanySettings.TaxMandatoryType;
        } else {
            // initialize with default value TaxMandatory
            this.taxTypeSelected();
        }

        // valid as long as you've visited this step, since we have a default value
        this.onboardingService.setCurrentStepIsValid(true);

        if (theme.theme === THEMES.SR) {
            this.logger.logPageView('Onboarding - Tax type');
        }
    }

    taxTypeSelected() {
        this.onboardingService.setTaxMandatoryType(this.selectedType);
    }
}
